/* src/components/Home.css */
.home-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
}

.home-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
}

h1 {
    color: #222;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.2em;
}

h2 {
    color: #0056b3;
    margin-top: 20px;
    font-size: 1.4em;
}

p, li {
    color: #444;
    margin-bottom: 12px;
}

code {
    background-color: #e9ecef;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 0.95em;
    font-family: 'Courier New', monospace;
    color: #c7254e;
}

a {
    color: #0056b3;
    text-decoration: none;
}

a:hover {
    color: #003d80;
}
